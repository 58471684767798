"use client";
import { CryptoCurrencies, Theme } from "@/shared/constants";

import CheckmarkIconLight from "@themedIcons/light/checkmark.svg?dataUrl";
import CheckmarkIconNight from "@themedIcons/night/checkmark.svg?dataUrl";
import CheckmarkIconEndemic from "@themedIcons/endemic/checkmark.svg?dataUrl";

import ActiveLinkLineIconLight from "@themedIcons/light/active-link-line.svg?dataUrl";
import ActiveLinkLineIconNight from "@themedIcons/night/active-link-line.svg?dataUrl";
import ActiveLinkLineIconEndemic from "@themedIcons/endemic/active-link-line.svg?dataUrl";

import EndyIconLight from "@themedIcons/light/endy.svg?dataUrl";
import EndyIconNight from "@themedIcons/night/endy.svg?dataUrl";
import EndyIconEndemic from "@themedIcons/endemic/endy.svg?dataUrl";

import LogoVIconLight from "@themedIcons/light/logo-v.svg?dataUrl";
import LogoVIconNight from "@themedIcons/night/logo-v.svg?dataUrl";
import LogoVIconEndemic from "@themedIcons/endemic/logo-v.svg?dataUrl";

import LogoIconLight from "@themedIcons/light/logo.svg?dataUrl";
import LogoIconNight from "@themedIcons/night/logo.svg?dataUrl";
import LogoIconEndemic from "@themedIcons/endemic/logo.svg?dataUrl";

import AppleIconLight from "@themedIcons/light/apple.svg?dataUrl";
import AppleIconNight from "@themedIcons/night/apple.svg?dataUrl";
import AppleIconEndemic from "@themedIcons/endemic/apple.svg?dataUrl";

import EtherIconLight from "@themedIcons/light/ethereum.svg?dataUrl";
import EtherIconNight from "@themedIcons/night/ethereum.svg?dataUrl";
import EtherIconEndemic from "@themedIcons/endemic/ethereum.svg?dataUrl";

import EtherSolidIconLight from "@themedIcons/light/ethereum-solid.svg?dataUrl";
import EtherSolidIconNight from "@themedIcons/night/ethereum-solid.svg?dataUrl";
import EtherSolidIconEndemic from "@themedIcons/endemic/ethereum-solid.svg?dataUrl";

import WrappedEtherIconLight from "@themedIcons/light/wrapped-ether.svg?dataUrl";
import WrappedEtherIconNight from "@themedIcons/night/wrapped-ether.svg?dataUrl";
import WrappedEtherIconEndemic from "@themedIcons/endemic/wrapped-ether.svg?dataUrl";

import MaticIconLight from "@themedIcons/light/matic.svg?dataUrl";
import MaticIconNight from "@themedIcons/night/matic.svg?dataUrl";
import MaticIconEndemic from "@themedIcons/endemic/matic.svg?dataUrl";

import EndyV2Light from "@themedIcons/light/endy-v2.svg?dataUrl";
import EndyV2Night from "@themedIcons/night/endy-v2.svg?dataUrl";
import EndyV2Endemic from "@themedIcons/endemic/endy-v2.svg?dataUrl";

import EndyWaitingLight from "@themedIcons/light/endy-waiting.svg?dataUrl";
import EndyWaitingNight from "@themedIcons/night/endy-waiting.svg?dataUrl";
import EndyWaitingEndemic from "@themedIcons/endemic/endy-waiting.svg?dataUrl";

import EndyDeniedLight from "@themedIcons/light/endy-denied.svg?dataUrl";
import EndyDeniedNight from "@themedIcons/night/endy-denied.svg?dataUrl";
import EndyDeniedEndemic from "@themedIcons/endemic/endy-denied.svg?dataUrl";

import EndyReadyLight from "@themedIcons/light/endy-ready.svg?dataUrl";
import EndyReadyNight from "@themedIcons/night/endy-ready.svg?dataUrl";
import EndyReadyEndemic from "@themedIcons/endemic/endy-ready.svg?dataUrl";

import EndyCanceledLight from "@themedIcons/light/endy-canceled.svg?dataUrl";
import EndyCanceledNight from "@themedIcons/night/endy-canceled.svg?dataUrl";
import EndyCanceledEndemic from "@themedIcons/endemic/endy-canceled.svg?dataUrl";

import RankingsMobileLight from "@themedIcons/light/rankingsMobileMenu.svg?dataUrl";
import RankingsMobileNight from "@themedIcons/night/rankingsMobileMenu.svg?dataUrl";
import RankingsMobileEndemic from "@themedIcons/endemic/rankingsMobileMenu.svg?dataUrl";

import ActivityMobileLight from "@themedIcons/light/activityMobileMenu.svg?dataUrl";
import ActivityMobileNight from "@themedIcons/night/activityMobileMenu.svg?dataUrl";
import ActivityMobileEndemic from "@themedIcons/endemic/activityMobileMenu.svg?dataUrl";

import CollectionsMobileLight from "@themedIcons/light/collectionsMobileMenu.svg?dataUrl";
import CollectionsMobileNight from "@themedIcons/night/collectionsMobileMenu.svg?dataUrl";
import CollectionsMobileEndemic from "@themedIcons/endemic/collectionsMobileMenu.svg?dataUrl";

import ArtistsMobileLight from "@themedIcons/light/artistsMobileMenu.svg?dataUrl";
import ArtistsMobileNight from "@themedIcons/night/artistsMobileMenu.svg?dataUrl";
import ArtistsMobileEndemic from "@themedIcons/endemic/artistsMobileMenu.svg?dataUrl";

import ArtworkMobileLight from "@themedIcons/light/artworkMobileMenu.svg?dataUrl";
import ArtworkMobileNight from "@themedIcons/night/artworkMobileMenu.svg?dataUrl";
import ArtworkMobileEndemic from "@themedIcons/endemic/artworkMobileMenu.svg?dataUrl";

import AllPostsMobileLight from "@themedIcons/light/all-postsMobileMenu.svg?dataUrl";
import AllPostsMobileNight from "@themedIcons/night/all-postsMobileMenu.svg?dataUrl";
import AllPostsMobileEndemic from "@themedIcons/endemic/all-postsMobileMenu.svg?dataUrl";

import NewsMobileLight from "@themedIcons/light/newsMobileMenu.svg?dataUrl";
import NewsMobileNight from "@themedIcons/night/newsMobileMenu.svg?dataUrl";
import NewsMobileEndemic from "@themedIcons/endemic/newsMobileMenu.svg?dataUrl";

import ArtAndArtistsMobileLight from "@themedIcons/light/art-and-artistsMobileMenu.svg?dataUrl";
import ArtAndArtistsMobileNight from "@themedIcons/night/art-and-artistsMobileMenu.svg?dataUrl";
import ArtAndArtistsMobileEndemic from "@themedIcons/endemic/art-and-artistsMobileMenu.svg?dataUrl";

import KnowHowMobileLight from "@themedIcons/light/know-howMobileMenu.svg?dataUrl";
import KnowHowMobileNight from "@themedIcons/night/know-howMobileMenu.svg?dataUrl";
import KnowHowMobileEndemic from "@themedIcons/endemic/know-howMobileMenu.svg?dataUrl";

import CollectivesMobileLight from "@themedIcons/light/collectivesMobileMenu.svg?dataUrl";
import CollectivesMobileNight from "@themedIcons/night/collectivesMobileMenu.svg?dataUrl";
import CollectivesMobileEndemic from "@themedIcons/endemic/collectivesMobileMenu.svg?dataUrl";

export type IconNames =
  | "checkmark"
  | "active-link-line"
  | "endy"
  | "logo-v"
  | "logo"
  | "apple"
  | "endyV2"
  | "endy-waiting"
  | "endy-denied"
  | "endy-ready"
  | "endy-canceled"
  | "rankings-mobile"
  | "activity-mobile"
  | "collections-mobile"
  | "artists-mobile"
  | "artwork-mobile"
  | "all-posts-mobile"
  | "news-mobile"
  | "art-and-artists-mobile"
  | "know-how-mobile"
  | "collectives-mobile"
  | CryptoCurrencies.Ether
  | `${CryptoCurrencies.Ether}-solid`
  | CryptoCurrencies.Matic
  | CryptoCurrencies.Weth;

type ThemedIcons = Record<IconNames, Record<Theme, any>>;
// prettier-ignore
export const ICONS: ThemedIcons = {
  checkmark: {
    [Theme.Light]: CheckmarkIconLight,
    [Theme.Night]: CheckmarkIconNight,
    [Theme.Endemic]: CheckmarkIconEndemic,
  },
  "active-link-line": {
    [Theme.Light]: ActiveLinkLineIconLight,
    [Theme.Night]: ActiveLinkLineIconNight,
    [Theme.Endemic]: ActiveLinkLineIconEndemic,
  },
  endy: {
    [Theme.Light]: EndyIconLight,
    [Theme.Night]: EndyIconNight,
    [Theme.Endemic]: EndyIconEndemic,
  },
  "logo-v": {
    [Theme.Light]: LogoVIconLight,
    [Theme.Night]: LogoVIconNight,
    [Theme.Endemic]: LogoVIconEndemic,
  },
  logo: {
    [Theme.Light]: LogoIconLight,
    [Theme.Night]: LogoIconNight,
    [Theme.Endemic]: LogoIconEndemic,
  },
  apple: {
    [Theme.Light]: AppleIconLight,
    [Theme.Night]: AppleIconNight,
    [Theme.Endemic]: AppleIconEndemic,
  },
  ethereum: {
    [Theme.Light]: EtherIconLight,
    [Theme.Night]: EtherIconNight,
    [Theme.Endemic]: EtherIconEndemic,
  },
  "ethereum-solid": {
    [Theme.Light]: EtherSolidIconLight,
    [Theme.Night]: EtherSolidIconNight,
    [Theme.Endemic]: EtherSolidIconEndemic,
  },
  "wrapped-ether": {
    [Theme.Light]: WrappedEtherIconLight,
    [Theme.Night]: WrappedEtherIconNight,
    [Theme.Endemic]: WrappedEtherIconEndemic,
  },
  matic: {
    [Theme.Light]: MaticIconLight,
    [Theme.Night]: MaticIconNight,
    [Theme.Endemic]: MaticIconEndemic,
  },
  endyV2: {
    [Theme.Light]: EndyV2Light,
    [Theme.Night]: EndyV2Night,
    [Theme.Endemic]: EndyV2Endemic,
  },
  "endy-canceled": {
    [Theme.Light]: EndyCanceledLight,
    [Theme.Night]: EndyCanceledNight,
    [Theme.Endemic]: EndyCanceledEndemic,
  },
  "endy-denied": {
    [Theme.Light]: EndyDeniedLight,
    [Theme.Night]: EndyDeniedNight,
    [Theme.Endemic]: EndyDeniedEndemic,
  },
  "endy-ready": {
    [Theme.Light]: EndyReadyLight,
    [Theme.Night]: EndyReadyNight,
    [Theme.Endemic]: EndyReadyEndemic,
  },
  "endy-waiting": {
    [Theme.Light]: EndyWaitingLight,
    [Theme.Night]: EndyWaitingNight,
    [Theme.Endemic]: EndyWaitingEndemic,
  },
  "rankings-mobile": {
    [Theme.Light]: RankingsMobileLight,
    [Theme.Night]: RankingsMobileNight,
    [Theme.Endemic]: RankingsMobileEndemic,
  },
  "activity-mobile": {
    [Theme.Light]: ActivityMobileLight,
    [Theme.Night]: ActivityMobileNight,
    [Theme.Endemic]: ActivityMobileEndemic,
  },
  "collections-mobile": {
    [Theme.Light]: CollectionsMobileLight,
    [Theme.Night]: CollectionsMobileNight,
    [Theme.Endemic]: CollectionsMobileEndemic,
  },
  "artists-mobile": {
    [Theme.Light]: ArtistsMobileLight,
    [Theme.Night]: ArtistsMobileNight,
    [Theme.Endemic]: ArtistsMobileEndemic,
  },
  "artwork-mobile": {
    [Theme.Light]: ArtworkMobileLight,
    [Theme.Night]: ArtworkMobileNight,
    [Theme.Endemic]: ArtworkMobileEndemic,
  },
  "all-posts-mobile": {
    [Theme.Light]: AllPostsMobileLight,
    [Theme.Night]: AllPostsMobileNight,
    [Theme.Endemic]: AllPostsMobileEndemic,
  },
  "news-mobile": {
    [Theme.Light]: NewsMobileLight,
    [Theme.Night]: NewsMobileNight,
    [Theme.Endemic]: NewsMobileEndemic,
  },
  "art-and-artists-mobile": {
    [Theme.Light]: ArtAndArtistsMobileLight,
    [Theme.Night]: ArtAndArtistsMobileNight,
    [Theme.Endemic]: ArtAndArtistsMobileEndemic,
  },
  "know-how-mobile": {
    [Theme.Light]: KnowHowMobileLight,
    [Theme.Night]: KnowHowMobileNight,
    [Theme.Endemic]: KnowHowMobileEndemic,
  },
  "collectives-mobile": {
    [Theme.Light]: CollectivesMobileLight,
    [Theme.Night]: CollectivesMobileNight,
    [Theme.Endemic]: CollectivesMobileEndemic,
  },
};
